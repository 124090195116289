import React from "react";
import { graphql } from "gatsby";
import ReactCompareImage from "react-compare-image";

import Slider from "react-slick";
import Layout from "../Layout/Layout";
import renderHTML from "../helpers/renderHTML";
import SEO from "../Layout/Seo";

const PageSingleServiceTemplate = props => {
  const service = props.data.wordpressWpServices;
  const cleanDescription = service.excerpt.replace(/<\/?[^>]+(>|$)/g, "");
  // const video = service.acf.additional_video // eslint-disable-line no-nested-ternary
  //   ? service.acf.additional_video
  //   : null;

  const settings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <Layout>
      <SEO
        title={service.yoast.title ? service.yoast.title : service.title}
        keywords={service.yoast.metakeywords}
        description={service.yoast.metadesc ? service.yoast.metadesc : cleanDescription}
      />
      <div className={`services-wrapper single-services ${service.slug}`}>
        <section
          className="head"
          style={{
            backgroundImage: `url(${service.acf.main_header_image.localFile.childImageSharp.original.src})`
          }}
        >
          <div className="head__overlay" />
          <h1
            className="section__title--large head__title"
            dangerouslySetInnerHTML={renderHTML(service.title)}
          />
        </section>
        <section className="header container">
          <h2
            className="header__title"
            dangerouslySetInnerHTML={renderHTML(service.acf.service_main_header)}
          />
          <div
            className="header__description"
            dangerouslySetInnerHTML={renderHTML(service.acf.service_main_header_description)}
          />
        </section>

        <section className="main-cta services-cta services-cta--top">
          <div className="container">
            <div className="section__header">
              <div className="section__title section__title--contact">
                {service.acf.top_cta_content}
              </div>
            </div>
            <div className="section__button">
              <div className="cta__content cta__content--what-we-can-do">
                <div className="join-us-button">
                  <a href="/contact">
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <div className="btn__cta btn__cta--green btn__cta--contactus">Let's talk!</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {service.acf.is_case_study_section_enabled === true ? (
          <section className="case-studies">
            <div className="services__header">
              <div className="section__title--small">our Client</div>
              <h2 className="section__title--large">Case Studies</h2>
            </div>
            <div className="case-studies__content">
              {service.acf.case_studies.map(casestudy => {
                return (
                  <a href={casestudy.case_study_link} className="case-studies__single">
                    <div
                      className="case-studies__inner"
                      style={{
                        backgroundImage: `url(${casestudy.case_study_image.localFile.childImageSharp.original.src})`
                      }}
                    />
                    <div className="case-studies__description">
                      <h3>{casestudy.case_study_title}</h3>
                      <p className="case-studies__text">{casestudy.case_study_description}</p>
                      <p>{casestudy.case_study_hashtags}</p>
                    </div>
                  </a>
                );
              })}
            </div>
          </section>
        ) : (
          <></>
        )}

        {service.acf.is_process_of_development_enabled === true ? (
          <section className="process container">
            <div className="services__header">
              <div className="section__title--small">
                See what the Magento development process looks like
              </div>
              <h2 className="section__title--large">MAGENTO SHOP</h2>
            </div>
            <div className="process__content">
              <p>
                We work in a <b>Scrum methodology</b> which allows us to deliver value to the client
                in a 1-2 week iterations.
              </p>

              {/* To change to SVG */}
              <img
                src="https://wp.pandagroup.co/wp-content/uploads/2020/11/services.png"
                alt="Process Of Development"
              />
              <div className="process__description">
                <div className="process__column">
                  <p>
                    <div className="hexagon" />
                    <b>Product backlog</b> - here are all new features/improvements/bugs which are
                    waiting to be implemented in Magento store.
                  </p>
                  <p>
                    <div className="hexagon" />
                    <b>Sprint planning</b> - client decide with the Magento developers what tasks
                    are the most important for the next sprint and a result of that planning is a
                    sprint backlog.
                  </p>
                  <p>
                    <div className="hexagon" />
                    <b>Sprint backlog</b> - is a list of the tasks which are planned to be completed
                    within the next sprint. Usually, sprint length is 1-2 weeks.
                  </p>
                </div>
                <div className="process__column">
                  <p>
                    <div className="hexagon" />
                    <b>Daily scrum</b> - every day during the sprint, the Magento developers gather
                    for 10-15 minutes to discuss progress and any obstacles which they have. It
                    helps to solve issues quicker and deliver sprint backlog on time.
                  </p>
                  <p>
                    <div className="hexagon" />
                    <b>Sprint review</b> - at the end of the sprint, the Magento experts team
                    gathers with the client (Product Owner) to present work done in the last sprint.
                    <p>
                      <div className="hexagon" />
                      <b>Increment</b> - all work accepted by the client is deployed to the live
                      site and functionalities which requires some changes are moved to the next
                      sprint.
                    </p>
                  </p>
                </div>
                <div className="process__column">
                  <p>
                    <div className="hexagon" />
                    <b>Sprint retrospective</b> - is a meeting for the Magento development team to
                    discuss what they like and dislike in the past sprint. They identify issues in
                    the process like problems in communication, lack of test credentials, and other
                    things that were blocking them from more efficient work. <br />
                    <br /> The outcome of that meeting is at least one improvement which should be
                    applied in the next sprint. That allows the web design and development services
                    team to work more efficiently from sprint from sprint to sprint and deliver
                    better ecommerce products.
                  </p>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <></>
        )}

        {service.acf.is_content_with_image_enabled === true ? (
          <section className="imagecontent container">
            <div className="services__header">
              <div className="section__title--small">
                {service.acf.content_with_image_title_additional}
              </div>
              <h2 className="section__title--large">{service.acf.content_with_image_title}</h2>
            </div>
            <div className="imagecontent__description">
              <div className="imagecontent__text">
                <div dangerouslySetInnerHTML={renderHTML(service.acf.content_with_image_text)} />
              </div>
              <div
                style={{
                  backgroundImage: `url(${service.acf.content_with_image_photo.localFile.childImageSharp.original.src})`
                }}
                alt="Image for Magento Development"
                className="imagecontent__image"
              />
            </div>
          </section>
        ) : (
          <></>
        )}

        {service.acf.is_medium_cta_enabled === true ? (
          <section className="main-cta services-cta services-cta--medium">
            <div className="container">
              <div className="section__header">
                <div className="section__title section__title--contact">
                  {service.acf.medium_cta_content}
                </div>
              </div>
              <div className="section__button">
                <div className="cta__content cta__content--what-we-can-do">
                  <div className="join-us-button">
                    <a href="/contact">
                      <div className="btn__cta btn__cta--green btn__cta--contactus">
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        Let's talk!
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <></>
        )}

        {service.acf.is_clients_section_enabled === true ? (
          <section className="clients container">
            <div className="services__header">
              <div className="section__title--small">Our</div>
              <h2 className="section__title--large">Clients</h2>
            </div>
            <div className="clients__content">
              <Slider {...settings}>
                {service.acf.clients_logos.map(logo => {
                  return (
                    <img
                      src={logo.clients_logo_image.localFile.childImageSharp.original.src}
                      alt="Magento Client Logo"
                    />
                  );
                })}
              </Slider>
            </div>
          </section>
        ) : (
          <></>
        )}

        {service.acf.is_before_after_section_enabled === true ? (
          <section className="beforeafter container">
            <div className="services__header">
              <h2 className="section__title--large">{service.acf.before_after_header}</h2>
              <div className="section__title--small">{service.acf.before_after_content}</div>
            </div>
            <div className="beforeafter__content">
              <ReactCompareImage
                leftImage={service.acf.before_image.localFile.childImageSharp.original.src}
                rightImage={service.acf.after_image.localFile.childImageSharp.original.src}
              />
              <p>Before/After</p>
            </div>
          </section>
        ) : (
          <></>
        )}

        {/* {video.url ? ( */}
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        {/*  <video controls className="video"> */}
        {/*    <source src={`${video.url.source_url}#t=0.5`} type={video.url.mime_type} /> */}
        {/*    Your browser does not support the video tag. */}
        {/*  </video> */}
        {/* ) : null} */}

        {service.acf.is_known_companies_benefits_section_enabled === true ? (
          <section className="knowncompanies container">
            <div className="services__header">
              <div className="section__title--small">Known companies benefits</div>
              <h2 className="section__title--large">From PWA implementation</h2>
            </div>
            <div className="knowncompanies__content">
              <div className="knowncompanies__column knowncompanies__column--aliexpress">
                <img
                  src="https://wp.pandagroup.co/wp-content/uploads/2017/04/AliExpress.png"
                  alt="Aliexpress logo"
                />
                <p className="knowncompanies__description">
                  AliExpress improved conversion fot new customers by
                </p>
                <p className="knowncompanies__counter">104%</p>
              </div>

              <div className="knowncompanies__column knowncompanies__column--starbucks">
                <img
                  src="https://wp.pandagroup.co/wp-content/uploads/2017/04/starbucks.png"
                  alt="Starbucks logo"
                />
                <p className="knowncompanies__description">
                  The Starbucks with PWA has increased active users by
                </p>
                <p className="knowncompanies__counter">2 time</p>
              </div>

              <div className="knowncompanies__column knowncompanies__column--tinder">
                <img
                  src="https://wp.pandagroup.co/wp-content/uploads/2017/04/tinder.png"
                  alt="Tinder logo"
                />
                <p className="knowncompanies__description">Tinder decreased loading time by</p>
                <p className="knowncompanies__counter">60%</p>
              </div>
            </div>
          </section>
        ) : (
          <></>
        )}

        {service.acf.is_case_study_medium_section_enabled === true ? (
          <section className="case-studies">
            <div className="services__header">
              <div className="section__title--small">our Client</div>
              <h2 className="section__title--large">Case Studies</h2>
            </div>
            <div className="case-studies__content">
              {service.acf.case_studies_medium.map(casestudy => {
                return (
                  <a href={casestudy.case_study_link} className="case-studies__single">
                    <div
                      className="case-studies__inner"
                      style={{
                        backgroundImage: `url(${casestudy.case_study_image.localFile.childImageSharp.original.src})`
                      }}
                    />
                    <div className="case-studies__description">
                      <h3>{casestudy.case_study_title}</h3>
                      <p className="case-studies__text">{casestudy.case_study_description}</p>
                      <p>{casestudy.case_study_hashtags}</p>
                    </div>
                  </a>
                );
              })}
            </div>
          </section>
        ) : (
          <></>
        )}

        {service.acf.is_benefits_section_enabled === true ? (
          <section className="benefits container">
            <div className="benefits__content">
              <p>
                According to Google improving your load time by <span>0.1s</span> can boost
                conversion rates by <span>8%</span>.
              </p>
              <p>
                According to Think with Google <span>77%</span> of smartphone shoppers are more
                likely <b>to purchase</b> from companies whose mobile sites or apps{" "}
                <b>allow them to make purchases quickly</b>.
              </p>
            </div>
            <img
              src="https://wp.pandagroup.co/wp-content/uploads/2020/09/fce443e72c0b53c55bd0680caec43c1e-1.png"
              alt="Magento PWA Development"
            />
          </section>
        ) : (
          <></>
        )}

        {service.acf.is_additional_section_enabled === true ? (
          <section className="additional header container seo__box">
            <div className="header__title seo__box--left">
              <h3>Choose the best Magento development company</h3>
            </div>
            <div className="header__description seo__box--right">
              <p>
                Get comprehensive service for your store, no matter if you need to set up an online
                store from scratch, transfer an online store from another platform to Magento or
                migrate from Magento 1 to Magento 2. In our team, we have qualified Magento experts
                who will take care of your Magento ecommerce website. <br />
                <br />
                With us, you can focus on your ecommerce business and leave us the technical part of
                your online store. We know that it is not easy to find Magento developers, that is
                why provide frontend and backend developers. With our help, you can move Magento 2
                to the next level with PWA implementation of your ecommerce website. Among our
                services, you will find manual and automatic testing which provides additional
                security for your ecommerce website.
              </p>
            </div>
          </section>
        ) : (
          <></>
        )}

        {service.acf.is_seo_content_section_enabled === true ? (
          <section className="seo container">
            <div className="services__header">
              <h2 className="section__title--large">{service.acf.seo_content_title}</h2>
            </div>
            <div className="seo__content">
              {service.acf.seo_content_fields.map(field => {
                return (
                  <div className="seo__box">
                    <div className="seo__box--left">
                      <h3 dangerouslySetInnerHTML={renderHTML(field.seo_content_section)} />
                    </div>
                    <div
                      className="seo__box--right"
                      dangerouslySetInnerHTML={renderHTML(field.seo_content_description)}
                    />
                  </div>
                );
              })}
            </div>
          </section>
        ) : (
          <></>
        )}

        {service.acf.is_summary_section_enabled === true ? (
          <section className="summary header container seo__box">
            <div className="header__title seo__box--left">
              <h2 dangerouslySetInnerHTML={renderHTML(service.acf.summary_title)} />
            </div>
            <div
              className="header__description seo__box--right"
              dangerouslySetInnerHTML={renderHTML(service.acf.summary_description)}
            />
          </section>
        ) : (
          <></>
        )}

        <section className="main-cta services-cta">
          <div className="container">
            <div className="section__header">
              <div className="section__title section__title--contact">
                {service.acf.bottom_cta_text}
              </div>
            </div>
            <div className="section__button">
              <div className="cta__content cta__content--what-we-can-do">
                <div className="join-us-button">
                  <a href="/contact">
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <div className="btn__cta btn__cta--green btn__cta--contactus">Let's talk!</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default PageSingleServiceTemplate;

export const pageQuery = graphql`
  query currentServicePageQuery($id: String!) {
    wordpressWpServices(id: { eq: $id }) {
      id
      title
      excerpt
      slug
      acf {
        service_main_header
        service_main_header_description
        main_header_image {
          id
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        top_cta_content
        case_studies {
          case_study_description
          case_study_hashtags
          case_study_link
          case_study_title
          case_study_image {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
        content_with_image_title
        content_with_image_title_additional
        content_with_image_text
        content_with_image_photo {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        medium_cta_content
        clients_logos {
          clients_logo_image {
            localFile {
              id
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
        before_after_content
        before_after_header
        after_image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        before_image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        additional_video
        seo_content_fields {
          seo_content_description
          seo_content_section
        }
        seo_content_title
        summary_title
        summary_description
        is_seo_content_section_enabled
        is_process_of_development_enabled
        is_medium_cta_enabled
        is_known_companies_benefits_section_enabled
        is_content_with_image_enabled
        is_clients_section_enabled
        is_benefits_section_enabled
        is_case_study_section_enabled
        is_before_after_section_enabled
        is_additional_section_enabled
        is_summary_section_enabled
        bottom_cta_text
      }
      wordpress_id

      yoast {
        title
        metakeywords
        metadesc
      }
    }
    allWordpressWpPortfolio {
      edges {
        node {
          id
          wordpress_id
          title
          path
          acf {
            page_logo {
              id
              link
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            country_flag
            what_wewe_done
            shop_link
            services {
              icon
              name
            }
          }
        }
      }
    }
  }
`;
